var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "error-alert mt-5",
      attrs: {
        value: !!_vm.errorMessage,
        dismissible: "",
        "close-icon": "mdi-close",
        icon: "mdi-alert",
        color: "#212121",
        dark: "",
        "icon-color": "#f3f3f3",
      },
    },
    [_c("span", [_vm._v(_vm._s(_vm.errorMessage))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }