var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "message-box overflow-hidden",
      attrs: { elevation: "0", outlined: "" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "text-center" },
        [
          _c("p", { staticClass: "text-center py-3 font-21 para-text" }, [
            _vm._v(_vm._s(_vm.successMessage)),
          ]),
          _c(
            "v-icon",
            { staticClass: "mt-8", attrs: { size: "40", color: "secondary" } },
            [_vm._v("mdi-check-circle-outline")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }