









// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  name: 'SuccessMessage',
  data() {
    return {
    };
  },
  props: {
    successMessage: ''
  }
});
